import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import './drawer.scss';
import closeBtn from '../../../images/icons/close_btn_white.svg';
import { logout, onLogoutClearStoreData, setToastMessage } from '../../../js/redux/actions';
import useAuth from '../../../Routes/useAuth';
import UserProfileIcon from  '../../../images/icons/user_default.svg';
import ReportsIcon from  './Icons/ReportsIcon';
import ReportSpamIcon from  './Icons/ReportSpamIcon';
import MyReportsIcon from  './Icons/MyReportsIcon';
import LogoutIcon from  './Icons/LogoutIcon';
import HomeIcon from './Icons/HomeIcon';
import Button from '../Button';
import ScamyodhaLogo from '../../../images/icons/scamyodha_app_text_logo.svg';
import { CONTACT_MAIL } from '../../../js/constants';

const DrawerIcon = ({focused, route}) =>{
    let icon;
    const fill = focused?'#549AFF':'#121212';
    switch (route) {
        case 'Home':
            icon = <HomeIcon fill={fill} />
            break;
        case 'Reports':
            icon = <ReportsIcon fill={fill} />
            break;
        case 'Report Spam':
            icon = <ReportSpamIcon fill={fill} />
            break;
        case 'My Reports':
            icon = <MyReportsIcon fill={fill} />
            break;
        case 'Logout':
            icon = <LogoutIcon fill={fill} />
            break;
        default:
            icon = null;
            break;
    }
    return icon;
}

export default function Drawer({ showDrawer, toggleDrawer }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { auth: { isAuthenticated }, setAuthData, userData } = useAuth();
    const [activeMenuItem, setActiveMenuItem] = useState(pathname || null);
    const DRAWER_MENU_LIST = [
        {   
            id:0,
            label:'Home',
            route:'/home',
            pathName:'/home',
            isVisible: true,
        },
        {   
            id:1,
            label:'Reports',
            route:'/reports?type=number&status=ACTIVE&reportType=all',
            pathName:'/reports',
            isVisible: true,
        },
        {   
            id:3,
            label:'Report Spam',
            route:'/report-spam',
            pathName:'/report-spam',
            isVisible: isAuthenticated,
        },
        {   
            id:4,
            label:'My Reports',
            route:'/reports/my-report?type=number&status=ACTIVE',
            pathName:'/reports/my-report',
            isVisible: isAuthenticated,
        },
    ];
    const year = new Date();


    const handleLogout = () => {
        toggleDrawer();
        setActiveMenuItem('Logout');
        dispatch(logout()).then(resp => {
            if (resp?.data?.success) {
                dispatch(
                    setToastMessage({
                        status: "success",
                        message: resp?.data?.message,
                    })
                );
                dispatch(onLogoutClearStoreData());
                setActiveMenuItem(null);
                setAuthData();
                navigate(`/auth/login`);
            }
        });
    }
    return (
        <div className={`fab-drawer ${showDrawer}`}>
            <div className="fab-drawer-content">
                <div className="fab-drawer-header">
                    <div className="fab-drawer-top-section">
                        {isAuthenticated && userData ?
                            (<>
                                <img src={UserProfileIcon} alt="user-profile" />
                                <div style={{ marginTop: 12 }}>
                                    <div className="fab-drawer-profile-name">
                                        {`Hey, ${userData?.name}!`}
                                    </div>
                                    <div className="fab-drawer-profile-phoneno">
                                        {`${userData?.countryCode} ${userData?.phoneNo}`}
                                    </div>
                                </div>
                            </>) : (<div className="fab-drawer-logo">
                                <img src={ScamyodhaLogo} alt="scamyodha-logo" />
                            </div>)}
                    </div>
                    <div className="fab-drawer-close-btn" onClick={() => toggleDrawer()}>
                        <img src={closeBtn} alt="close-btn" />
                    </div>
                </div>
                <div className="fab-drawer-body">
                    <div className="fab-drawer-menu">
                        {DRAWER_MENU_LIST.filter(item => item.isVisible).map(item => (
                            <div key={item.label} className={`fab-drawer-menu-item ${activeMenuItem === item.pathName && 'active-item'}`}>
                                <Link to={item.route} className="fab-nav-link fab-drawer-menu-title" onClick={() => {
                                    setActiveMenuItem(item.pathName)
                                    toggleDrawer()
                                }}>
                                    <span><DrawerIcon route={item.label} focused={activeMenuItem === item.pathName} /></span>
                                    <div className="fab-drawer-menu-label" style={{ color: `${activeMenuItem === item.pathName ? '#549AFF' : '#121212'}` }}>{item.label}</div>
                                </Link>
                            </div>
                        ))}
                        {!isAuthenticated && <div className='fab-drawer-btn-section'>
                            <Button
                                onClick={() => {
                                    toggleDrawer()
                                    navigate(`/auth/login`)
                                }}
                                buttontext='Login'
                                customclass="primary-btn"
                                style={{ marginBottom: 16 }}
                            />
                            <Button
                                onClick={() => {
                                    toggleDrawer()
                                    navigate(`/auth/register`)
                                }}
                                buttontext='Register'
                                customclass="fab-btn-link"
                            />

                        </div>}
                    </div>
                </div>

                <div className="fab-drawer-footer">
                    {isAuthenticated && <div className="fab-drawer-menu-item">
                        <div className="fab-nav-link fab-drawer-menu-title" onClick={() => handleLogout()}>
                            <span><DrawerIcon route='Logout' focused={activeMenuItem === 'Logout'} /></span>
                            <div className="fab-drawer-menu-label" style={{ color: `${activeMenuItem === 'Logout' ? '#549AFF' : '#121212'}` }}>Logout</div>
                        </div>
                    </div>}
                    <div className='fab-drawer-links-wrapper'>
                        <div className='fab-drawer-footer-links'>
                            <Link to='/about' className="fab-nav-link" onClick={() => toggleDrawer()}>About</Link>

                            <Link to='/terms-of-use' className="fab-nav-link" onClick={() => toggleDrawer()}>Terms of Use</Link>
                        </div>
                        <div className='fab-drawer-footer-links'>
                            <a href={`mailto:${CONTACT_MAIL}`} className="fab-nav-link" onClick={() => toggleDrawer()}>Contact Us</a>
                            <Link to='/privacy-policy' className="fab-nav-link" onClick={() => toggleDrawer()}>Privacy Policy</Link>
                        </div>
                    </div>
                    <div className="fab-drawer-footer-bottom">
                        <div className='fab-drawer-footer-copyright'>
                            {`Copyright @ ${year.getFullYear()}. All Rights Reserved`}
                        </div>
                        <div className='fab-drawer-footer-createdby'>
                            Created by Ceegees Software Solutions
                        </div>
                    </div>
                </div>
            </div>
            <div id="app-main-overlay" onClick={() => toggleDrawer()} />
        </div>
    )
}